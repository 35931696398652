import { FC, HTMLAttributes } from 'react';
import Helmet, { HelmetProps } from 'react-helmet';

import OG, { IOGMeta } from './OG';
import Twitter, { ITwitterMeta } from './Twitter';

export interface IMeta {
  title?: string;
  description?: string;
  image?: string;
}

export interface ISEOProps extends HTMLAttributes<HTMLDivElement> {
  og?: IOGMeta;
  twitter?: ITwitterMeta;
  helmetProps?: HelmetProps;
  title?: string;
  description?: string;
  image?: string;
}

const SEO: FC<ISEOProps> = ({
  title = '',
  description = '',
  image = '',
  og: { type: ogType, siteUrl: ogSiteUrl, title: ogTitle } = {},
  twitter: { cardType } = {},
  helmetProps = {},
}) => {
  const language = 'en';
  return (
    <>
      <Helmet {...helmetProps}>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta httpEquiv="content-language" content={language} />
        {title && <title>{title}</title>}
        {title && <meta itemProp="name" content={title} />}
        {description && <meta itemProp="description" content={description} />}
        {description && <meta name="description" content={description} />}
        <script type="application/ld+json">
          {`
            {
              "@context":"http://schema.org",
              "@type":"Organization",
              "name":"World Master RPG",
              "url":"https://worldmasterrpg.com",
              "address":"",
              "sameAs":[
                "https://www.facebook.com/WorldMasterRPG/",
                "https://twitter.com/WorldMasterRPG/",
              ]
            }
        `}
        </script>
      </Helmet>
      <OG
        meta={{ title: ogTitle || title, description, image }}
        siteUrl={ogSiteUrl}
        type={ogType}
        helmetProps={helmetProps}
      />
      <Twitter meta={{ title, description, image }} helmetProps={helmetProps} cardType={cardType} />
    </>
  );
};

export default SEO;
