import { FC } from 'react';
import Helmet from 'react-helmet';

import { IMeta, ISEOProps } from 'components/atoms/SEO/index';

enum CardType {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
  Player = 'player',
  App = 'app',
}

export interface ITwitterMeta {
  cardType?: CardType;
}

interface ITwitter extends Pick<ISEOProps, 'helmetProps'>, ITwitterMeta {
  meta?: IMeta;
}

const Twitter: FC<ITwitter> = ({
  cardType = CardType.Summary,
  meta: { title, description, image } = {},
  helmetProps,
}) => (
  <Helmet {...helmetProps}>
    <meta name="twitter:card" content={cardType} />
    {title && <meta name="twitter:title" content={title} />}
    {image && <meta name="twitter:image" content={image} />}
    {description && <meta name="twitter:description" content={description} />}
  </Helmet>
);

export default Twitter;
