import { FC } from 'react';
import Helmet from 'react-helmet';

import { IMeta, ISEOProps } from 'components/atoms/SEO/index';

enum ContentType {
  Article = 'article',
  Website = 'website',
}

export interface IOGMeta {
  siteUrl?: string;
  type?: ContentType;
  title?: string;
}

interface IOG extends Pick<ISEOProps, 'helmetProps'>, IOGMeta {
  meta?: IMeta;
}

const OG: FC<IOG> = ({
  siteUrl,
  type = ContentType.Website,
  meta: { title, description, image } = {},
  helmetProps,
}) => (
  <Helmet {...helmetProps}>
    <meta property="og:type" content={type} />
    <meta property="og:url" content={siteUrl} />
    {title && <meta property="og:title" content={title} />}
    {image && <meta property="og:image" content={image} />}
    {description && <meta property="og:description" content={description} />}
  </Helmet>
);

export default OG;
