import { FC } from 'react';

import { Box, BoxProps, styled } from '@mui/material';

import './stars.css';

const StyledBackgroundBox = styled(Box)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: black no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
`;

const Overlay = styled('span')`
  width: 100vw;
  height: 100vh;
  position: absolute;
  opacity: 0.9;
  top: 0;
  //-webkit-box-shadow: inset 0px -20px 21px -5px #000000;
  //box-shadow: inset 0px -20px 21px -5px #000000;
`;

export const StarBackground: FC<BoxProps> = (props) => (
  <StyledBackgroundBox
    style={
      {
        // backgroundImage: `url('${castlePng}')`,
      }
    }
    {...props}
  >
    <div className="absolute">
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      <div id="stars4" />
    </div>
    <Overlay id="blackOverlay" className="backgroundStars" />
  </StyledBackgroundBox>
);
