import { FC, HTMLAttributes } from 'react';

import { Box, Link, ThemeProvider, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from 'components/atoms/SEO';
import { Header } from 'components/molecules/Header';
import theme from 'theme';

export interface ILayoutProps extends HTMLAttributes<HTMLDivElement> {
  isHeaderTextWhite?: boolean;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  const {
    site: {
      siteMetadata: { title, siteUrl, description, image },
    },
  } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
          description
          image
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box component="main" dir="ltr" sx={{ minHeight: '100vh' }}>
        <SEO
          {...{
            helmetProps: {
              defaultTitle: title,
              defer: false,
              titleTemplate: `${title} | %s`,
            },
            og: { siteUrl },
            title,
            image,
            description,
          }}
        />
        <Header />
        <Box
          sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
          {children}
          <Box component="footer" sx={{ position: 'relative', p: 3, marginTop: 'auto' }}>
            <Typography noWrap variant="body2" color="text.secondary" align="center">
              {`2018-${new Date().getFullYear()} © All rights reserved `}
              <Link color="inherit" href="https://worldmasterrpg.com">
                worldmasterrpg.com
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
